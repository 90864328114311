import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./@theme/layouts/complete-layout/complete-layout.module').then(m => m.CompleteLayoutModule)
  },
  {
    path: 'basic',
    loadChildren: () => import('./@theme/layouts/basic/basic.module').then(m => m.BasicModule)
  },
  {
    path: 'blank',
    loadChildren: () => import('./@theme/layouts/blank/blank.module').then(m => m.BlankModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }